@import "~scss/vars";

.Home {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}

.Home-large {
  font-size: 48px;
}

.Home-cta {
  display: grid;
  grid-gap: 1em;
  text-align: center;
  padding-top: 5em;
}

.Home-cta-link {
  line-height: 1.1;
  border-radius: 5px;
  color: white;
  display: block;
  max-width: 575px;
  margin: 0 auto;
  text-decoration: none;
  width: 100%;
  padding: 20px;
  text-align: left;

  &:hover {
    background-color: rgba(0,0,0,.6);
  }
}

.Home-cta-link-heading {
  font-size: 1.5em;

  @media (min-width: 600px) {
    font-size: 1.5em;
  }
}

.Home-cta-link-subheading {
  margin-top: .5em;
  font-size: 1em;
}

.Home-cta-link--primary {
  background-color: black;
}

.Home-cta-link--secondary {
  border: 2px solid;
}

.Home-facts {
  p {
    padding: 1em 0;
    //min-height: 80vh;
  }
}
.Home-facts-item-body {
  font-size: 30px;
  line-height: 1.33;

  @media (min-width: 400px) {
    font-size: 36px;
  }

  @media (min-width: $frameBreak) {
    font-size: 42px;
  }
}

.Home-fullscreenitem {
  min-height: calc(100vh - #{$navHeight});
  // margin-top: 3rem;
  // margin-bottom: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Home-facts-item {
  @extend .Home-fullscreenitem;

  .VideoEmbed {
    margin-top: 1em;
  }
}