@import '~scss/vars';

.Page {
  padding: 3em 1em;
  min-height: calc(100vh - #{$navHeight});
}

.Page a {
  text-decoration: none;
  border-bottom: 2px solid;

  &:hover {
    border-bottom: 0;
  }
}

.Page ul {
  padding-left: 1.5em;
}
.Page ul li {
  margin: 1em 0;
  //list-style-image: url("data:image/svg+xml,%3Csvg width='11' height='11' viewBox='0 0 11 11' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1h9v9H1z' stroke='%23FFF' stroke-width='2' fill='none' fill-rule='evenodd'/%3E%3C/svg%3E");
}