@import url(https://fast.fonts.net/lt/1.css?apiType=css&c=9edc3f58-7553-444f-9c44-0488d06805b0&fontids=5664115);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Helvetica Neue LT W05 85 Heavy";
  src: url(http://mtv.hyperakt.com/static/media/80e123ee-1929-41ec-bf6a-77f92f0d53fe.c169a4f4.eot?#iefix);
  src: url(http://mtv.hyperakt.com/static/media/80e123ee-1929-41ec-bf6a-77f92f0d53fe.c169a4f4.eot?#iefix) format("eot"), url(http://mtv.hyperakt.com/static/media/7e42a406-9133-48c0-a705-4264ac520b43.35ea6253.woff2) format("woff2"), url(http://mtv.hyperakt.com/static/media/837750e9-3227-455d-a04e-dc76764aefcf.fb4162ec.woff) format("woff"), url(http://mtv.hyperakt.com/static/media/cbb49e47-093c-4029-b64c-b5ee08064205.a2a44046.ttf) format("truetype"); }

.font-menu {
  font-size: 24px; }

* {
  box-sizing: inherit; }

body {
  box-sizing: border-box; }

a {
  color: inherit; }

body {
  font-family: "Helvetica Neue LT W05 85 Heavy";
  line-height: 1.5; }

main {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  padding-top: 58px;
  background-color: #9343FB;
  color: #fff;
  min-height: 100vh; }

.max {
  margin-left: auto;
  margin-right: auto; }

.iframe-wrapper {
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
  width: 100%; }

iframe {
  display: block;
  width: 100%;
  height: 100%; }

.frame {
  width: 100%;
  position: relative; }
  .frame .frame-inner {
    padding: 1em; }
  @media (min-width: 600px) {
    .frame {
      margin-left: auto;
      margin-right: auto; }
      .frame .frame-inner {
        padding: 0 3em;
        max-width: 800px;
        margin: 0 auto; }
      .frame.Home .frame-bar:before {
        background-color: #9343FB; } }

.frame-inner {
  padding: 1em; }

.quote-title {
  display: inline-block;
  background-color: #fff;
  color: #9343FB; }

.Chat .Home-cta {
  padding: 5em 10px; }

.VideoEmbed {
  border-radius: 15px;
  overflow: hidden; }

.VideoEmbed-placeholder {
  cursor: pointer; }
  .VideoEmbed-placeholder img {
    display: block;
    width: 100%; }
  .VideoEmbed-placeholder svg {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }

.VideoEmbed-modal {
  -webkit-align-items: center;
          align-items: center;
  background-color: #9343FB;
  cursor: pointer;
  display: -webkit-flex;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  z-index: 3;
  padding: 40px; }
  @media (min-width: 800px) {
    .VideoEmbed-modal {
      padding: 30px 100px; } }
  @media (min-width: 1200px) {
    .VideoEmbed-modal {
      padding: 30px 200px; } }
  .VideoEmbed-modal button {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 40px; }
  .VideoEmbed-modal iframe {
    -webkit-animation: fade 3s forwards;
            animation: fade 3s forwards; }

@-webkit-keyframes fade {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fade {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.iframe-height-wrapper {
  width: 100%;
  height: 100%; }

.iframe-ratio-wrapper {
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
  margin: auto;
  width: 100%; }
  .iframe-ratio-wrapper iframe {
    position: absolute;
    width: 100%;
    height: 100%; }

.VideoEmbed-modal-close {
  display: block;
  background-color: transparent;
  border: 0;
  padding: 0; }

.VideoEmbed-modal-close-bar {
  background-color: white;
  width: 80%;
  height: 4px;
  position: absolute;
  left: 10%;
  top: 40%; }
  .VideoEmbed-modal-close-bar:first-child {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .VideoEmbed-modal-close-bar:last-child {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }

.Frame-scroll {
  display: block;
  position: fixed;
  bottom: 25px;
  left: 50%;
  background-color: transparent;
  border: 0;
  width: 25px;
  height: 25px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  padding: 0; }
  .Frame-scroll:focus {
    -webkit-transform: translateX(-50%) scale(1.1);
            transform: translateX(-50%) scale(1.1);
    outline: 0; }

.Frame-scroll-bar {
  bottom: 0;
  position: absolute;
  height: 4px;
  width: 100%;
  -webkit-transform-origin: left center;
          transform-origin: left center; }
  .Frame-scroll-bar:first-child {
    left: calc(50% - 1px);
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
  .Frame-scroll-bar:last-child {
    left: calc(50% + 1px);
    -webkit-transform: rotate(-135deg);
            transform: rotate(-135deg); }

.logos {
  display: -webkit-flex;
  display: flex;
  width: 100%; }
  .logos figure {
    display: block;
    width: 100%;
    margin: 0; }
  .logos img {
    display: block;
    width: 100%; }

.font-menu {
  font-size: 24px; }

.Home {
  -webkit-align-items: flex-start;
          align-items: flex-start;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }

.Home-large {
  font-size: 48px; }

.Home-cta {
  display: grid;
  grid-gap: 1em;
  text-align: center;
  padding-top: 5em; }

.Home-cta-link {
  line-height: 1.1;
  border-radius: 5px;
  color: white;
  display: block;
  max-width: 575px;
  margin: 0 auto;
  text-decoration: none;
  width: 100%;
  padding: 20px;
  text-align: left; }
  .Home-cta-link:hover {
    background-color: rgba(0, 0, 0, 0.6); }

.Home-cta-link-heading {
  font-size: 1.5em; }
  @media (min-width: 600px) {
    .Home-cta-link-heading {
      font-size: 1.5em; } }

.Home-cta-link-subheading {
  margin-top: .5em;
  font-size: 1em; }

.Home-cta-link--primary {
  background-color: black; }

.Home-cta-link--secondary {
  border: 2px solid; }

.Home-facts p {
  padding: 1em 0; }

.Home-facts-item-body {
  font-size: 30px;
  line-height: 1.33; }
  @media (min-width: 400px) {
    .Home-facts-item-body {
      font-size: 36px; } }
  @media (min-width: 600px) {
    .Home-facts-item-body {
      font-size: 42px; } }

.Home-fullscreenitem, .Home-facts-item {
  min-height: calc(100vh - 58px);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center; }

.Home-facts-item .VideoEmbed {
  margin-top: 1em; }

.Share {
  margin: 2em auto;
  text-align: center; }

.Share-buttons {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center; }
  .Share-buttons .SocialMediaShareButton {
    cursor: pointer; }
  .Share-buttons svg {
    display: block;
    width: 40px;
    height: 40px;
    margin: 0 1em; }

.Share-cta {
  margin: .5em;
  text-transform: uppercase; }

.font-menu {
  font-size: 24px; }

.Chat .frame-inner {
  padding: 20px; }

.Message-inner,
.quote-title {
  position: relative; }
  .Message-inner > svg,
  .quote-title > svg {
    width: 25px;
    position: absolute;
    bottom: 0;
    left: -10px; }
    .Message-inner > svg *,
    .quote-title > svg * {
      fill: inherit; }

.Message {
  max-width: 250px;
  margin: 20px 0; }
  .Message .Message-body a {
    word-break: break-all; }
  @media (min-width: 500px) {
    .Message {
      max-width: 300px; } }
  @media (min-width: 900px) {
    .Message {
      max-width: 500px; } }
  .Message img {
    display: block;
    width: 100%; }

.Message-inner {
  display: inline-block; }

.Message--user {
  margin-left: auto; }
  .Message--user .Message-inner > svg {
    right: -10px;
    left: auto;
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg); }

.Message-body,
.Message-sender,
.Message-link,
.quote-title {
  padding-left: 25px;
  padding-right: 25px; }

.Message-body,
.Message-link,
.quote-title {
  border-radius: 30px;
  padding: 15px 25px; }

.Message-link img {
  border-top-left-radius: 30px;
  border-top-right-radius: 30px; }

.Message-body {
  display: inline-block;
  font-size: 14pt; }
  .Message-body p {
    margin-top: 0;
    margin-bottom: 0; }

.Message--sender .Message-inner > svg {
  fill: #9343FB; }

.Message--sender .Message-body {
  background-color: #9343FB;
  color: #fff; }

.Message--user .Message-inner {
  margin-left: auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }

.Message--user .Message-inner > svg {
  fill: #EAEAEA; }

.Message--user .Message-body {
  background-color: #EAEAEA;
  color: #9343FB; }

.Message-sender {
  color: black;
  opacity: .6;
  margin-bottom: .25em; }

.Message-link {
  display: inline-block;
  background-color: #5a5553;
  color: #fff;
  margin: 10px 0;
  text-decoration: none;
  padding-top: 0;
  padding-left: 0;
  padding-right: 0; }
  .Message-link:hover {
    -webkit-filter: brightness(1.1);
            filter: brightness(1.1); }

.Message-link-text {
  padding: 15px 30px 0; }

.Chat-heading {
  color: black;
  margin: 3em 0;
  font-size: 32px; }

.font-menu {
  font-size: 24px; }

.Page {
  padding: 3em 1em;
  min-height: calc(100vh - 58px); }

.Page a {
  text-decoration: none;
  border-bottom: 2px solid; }
  .Page a:hover {
    border-bottom: 0; }

.Page ul {
  padding-left: 1.5em; }

.Page ul li {
  margin: 1em 0; }

.font-menu {
  font-size: 24px; }

.Menu {
  background-color: #000;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  overflow: auto;
  width: 100%;
  z-index: 1; }

.Menu-inner {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  text-align: center;
  padding-top: 3em; }

.Menu-link {
  color: #fff;
  text-decoration: unset;
  margin: 1em 0; }

.Menu-link--small {
  font-size: 16px;
  margin: .25em 0; }

.Nav {
  -webkit-align-items: center;
          align-items: center;
  display: grid;
  grid-template-columns: 50px 1fr 50px;
  position: fixed;
  width: 100%;
  min-height: 58px;
  top: 0;
  left: 0;
  padding: 8px;
  z-index: 1; }
  .Nav svg {
    display: block;
    fill: inherit; }

.Nav-home {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  position: fixed;
  top: 14px;
  left: 8px;
  z-index: 2; }
  .Nav-home svg {
    display: block;
    height: 18px;
    width: auto; }

.Nav-home-local {
  margin-left: 1em;
  text-decoration: none;
  font-size: 18px; }

.Nav-title {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center; }

.Nav-home-divider {
  margin-left: .5rem; }
  .Nav-home-divider:after {
    display: block;
    content: '';
    height: 30px;
    border-left: 1px solid;
    margin-right: .5rem; }

.Burger {
  border: 0;
  background-color: transparent;
  width: 30px;
  height: 20px;
  position: fixed;
  padding: 0;
  z-index: 2;
  right: 18px;
  top: 18px;
  cursor: pointer; }
  .Burger:focus {
    outline: 0; }

.Burger-bar {
  width: 100%;
  height: 4px;
  position: absolute;
  top: calc(50% - 2px); }

