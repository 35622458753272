@import '~scss/vars';

.Menu {
  background-color: $black;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  overflow: auto;
  width: 100%;
  z-index: 1;
}

.Menu-inner {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding-top: 3em;
}

.Menu-link {
 color: $white;
 text-decoration: unset;
 margin: 1em 0;
}

.Menu-link--small {
  font-size: 16px;
  margin: .25em 0;
}